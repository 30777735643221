window.showAlert = (messageType, message) => {
  console.log(messageType);
  var notify = $.notify(message, {
      type: messageType,
      allow_dismiss: true,
      delay: 400000,
      showProgressbar: true,
      timer: 300,
      animate: {
          enter: "animated fadeInDown",
          exit: "animated fadeOutUp",
      },
    }
  );
}

// CHECK IF THERE IS ANY ACTION TO DO TO A TABULATOR
function checkTabulatorAfterAction(initiator) {
    const tabulator = initiator.closest('.tabulator-elt');

    // Check if a tabulator element exist
    if (tabulator != undefined) {
        const tablulatorInst = Tabulator.findTable(`#${tabulator.getAttribute('id')}`)[0];
        const deleteRowId = initiator.getAttribute('data-tabulator-deleteRow');

        // Delete a tabulator row if there is a row ID
        if (deleteRowId != undefined) { 
          tablulatorInst.deleteRow(deleteRowId);
        }
    }
} 

// >>> DISPLAY CONFIRM MODAL AFTER ACTIOB
window.swalConfirmed = (title) => {
  swal({
    title: title === undefined ? "L'action a bien été effectuée" : title,
    icon: "success",
  });
}

// DISPLAY CANCELED MODAL AFTER ACTION
window.swalCanceled = (title) => {
  swal({
    title: title === undefined ? "Action annulée" : title,
    icon: "error",
  });
}


$(document).ready(function() {

    // >>> FORM SENDING

    // >>> AJAX SETUP <<<
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    }); 
    
    // >>> SEE PASSWORD <<<
    $('.see-password').on('click', function() {
        let input = $(this).siblings('input');
        if (input.attr('type') == 'text') {
            $(this).removeClass('active flaticon-il-1').addClass('flaticon-il');
            input.attr('type', 'password');
        } else if (input.attr('type') == 'password') {
            $(this).addClass('active flaticon-il-1').addClass('flaticon-il');
            input.attr('type', 'text');
        }
    });
    

    // SWEET ALERT ACTION
    window.onDocumentEvent('click', '.confirm-action', function(_self, e) {
      e.preventDefault();

      const sweetType         = _self.getAttribute('data-sweet-type');
      const sweetTitle        = _self.getAttribute('data-sweet-title');
      const sweetText         = _self.getAttribute('data-sweet-text');
      const sweetConfirm      = _self.getAttribute('data-sweet-confirm');

      const sweetOptions = {
        title: sweetTitle || "Êtes-vous certain ?",
        text: sweetText || "Vous devez confirmer cette action pour pouvoir l'effectuer",
        icon: sweetType || null,
        buttons: {
          cancel: "Annuler",
          confirm: {
            text: sweetConfirm || "Confirmer",
            value: "confirm",
          },
        },
      }

      swal(sweetOptions).then((value) => {
        switch (value) {

          // When the action is confirmed
          case "confirm":
            const route = _self.getAttribute('href');
            const routeMethod = _self.getAttribute('data-href-method');

            if (route != undefined) {
                $.ajax({
                  url: route,
                  method: routeMethod || 'GET',
                }).then(res => {
                  console.log(res);
                  checkTabulatorAfterAction(_self);
                  swalConfirmed(res.message);
                });
            } else {
              swalConfirmed(res.message);
            }
            
            break;

          // When the action is canceled
          default:
            swalCanceled();
        }
      });
    });


    // RANGE DATE PICKER (flatpickr)
    const rangeDatePicker = document.querySelectorAll('.rangedatepickr');
    if (rangeDatePicker.length) {
      rangeDatePicker.forEach(picker => {
        rangeDatePicker.flatpickr({
          locale: 'fr',
          mode: 'range',
          dateFormat: 'd/m/Y',
        })
      });
    }


    // ALTERNATE BETWEEN MULTIPLE BOOTSTRAP MODALS
    $('[data-bs-toggle="modal"]').on('click', function() {
        const isInsideModal = $(this).closest('.modal').length;
        // If the button is inside a modal
        if (isInsideModal) {
          const currentModalInst = bootstrap.Modal.getInstance($(this).closest('.modal')[0]);
          const openedModal = $($(this).attr('data-bs-target'));

          // When the second modal has started to be hidden, open the first one
          openedModal.on('hide.bs.modal', function() {
            currentModalInst.show();
          });
        }
    });
	
});